import axios from 'axios';
//const qs = require('qs');
import Utils from '@/utils/Utils';
import router from "@/router";

import { environment } from '@/environment/environment';
import AuthService from '@/service/AuthService';
//import { EventBus } from '@/interceptor/EventBus';

const API_URL = environment.urlSrc;

class UtilsRequest {
    get(url, params, successFn, failFn, showMessageSuccess, responseType) {
        let configs = this.setParams('GET', url, params, responseType);

        this.create(configs, successFn, failFn, showMessageSuccess);
    };

    post(url, params, successFn, failFn, showMessageSuccess, responseType, contentType) {
        /*//Stringifica per form
        let data = qs.stringify(params);
        if(contentTypeToUse == "text/json" || contentTypeToUse == "application/json") {
            //Stringifico per JSON
            data = JSON.stringify(params);
        }*/
        let configs = this.setParams('POST', url, params, responseType, contentType);

        //post usata per salvataggio quindi di default showMessageSuccess -> true 
        showMessageSuccess = this.showMessageSuccess(showMessageSuccess)

        this.create(configs, successFn, failFn, showMessageSuccess);
    };

    put(url, params, successFn, failFn, showMessageSuccess, responseType, contentType) {
        let configs = this.setParams('PUT', url, params, responseType, contentType);

        //put usata per editing quindi di default showMessageSuccess -> true 
        showMessageSuccess = this.showMessageSuccess(showMessageSuccess)

        this.create(configs, successFn, failFn, showMessageSuccess);
    };

    patch(url, params, successFn, failFn, showMessageSuccess, responseType, contentType, config) {
        let configs = this.setParams('PATCH', url, params, responseType, contentType, config);

        //patch usata per modificare quindi di default showMessageSuccess -> true 
        showMessageSuccess = this.showMessageSuccess(showMessageSuccess)

        this.create(configs, successFn, failFn, showMessageSuccess);
    };

    delete(url, params, successFn, failFn, showMessageSuccess, responseType, contentType) {
        let configs = this.setParams('DELETE', url, params, responseType, contentType);

        //delete usata per eliminare quindi di default showMessageSuccess -> true 
        showMessageSuccess = this.showMessageSuccess(showMessageSuccess)

        this.create(configs, successFn, failFn, showMessageSuccess);
    };

    setParams(method, url, params, responseType, contentType, config) {
        let contentTypeToUse = "application/json";
        if (!Utils.isNullOrUndefined(contentType)) {
            contentTypeToUse = contentType;
        }
        let configs = {
            method: method,
            url: API_URL + url,
            withCredentials: true,
        }

        if (method === 'GET' || method === 'DELETE') {
            configs.params = params;
        } else {
            configs.data = params;
            configs.headers = {
                'Content-Type': contentTypeToUse
            };
        }

        if (!Utils.isNullOrUndefined(responseType)) {
            configs.responseType = responseType;
        }
        if (!Utils.isNullOrUndefined(config)) {
            configs = { ...configs, ...config }
        }

        return configs;
    }

    showMessageSuccess(showMessageSuccess) {
        if (Utils.isNullOrUndefined(showMessageSuccess)) {
            showMessageSuccess = true;
        }

        return showMessageSuccess;
    }

    create(configs, successFn, failFn, showMessageSuccess) {
        //AuthService.refreshTokens(() => 
        //{
            axios(configs)
                .then(response => this.success(response, successFn, failFn, showMessageSuccess))
                .catch(error => this.error(error, failFn))
        //});
    }

    success(response, successFn, failFn, showMessageSuccess) {
        successFn(response.data, response);
        /*if(!Utils.isNullOrUndefined(showMessageSuccess) && showMessageSuccess === true) {
            EventBus.setSnackbarFromResponse(response.data);
        }*/
    }

    error(error, failFn) {
        console.log("UtilsRequest - error", error)
        if (!Utils.isNullOrUndefined(error.response)) {
            if (!Utils.isNullOrUndefined(error.response.status)) {
                if (error.response.status == 401) {
                    router.push({ name: "login" }).catch(() => { });
                } else if (typeof failFn === 'function') {
                    failFn(error);
                }
            }
        }

        /*let message = "";
        if(!Utils.isNullOrUndefined(error) && !Utils.isNullOrUndefined(error.response) && !Utils.isNullOrUndefined(error.response.data.msg)) {
            message = error.response.data.msg;

            EventBus.$emit('open_dialog_error', {
                message: message
            });

            return;
        }*/

        // Recupera eventuale messaggio di errore di sistema
        if (!Utils.isNullOrUndefined(error.message)) {
            // Se errore di sistema printa l'errore solo in console
            console.log(error.message);
        }
    }
}

export default new UtilsRequest();
